.text-box {
    text-align: justify;
}
ul {
    margin: 0;
}
ul.dashed {
    list-style-type: none;
}
ul.dashed > li {
    text-indent: -5px;
    margin-bottom: 1%;
}
ul.dashed > li:before {
    content: '- ';
    text-indent: -5px;
}
ol {
    counter-reset: item;
}

ol > li {
    counter-increment: item;
    margin-bottom: 1%;
}
ol > li::before {
    content: '';
    width: 10px;
    display: inline-block;
}
ol ol > li::before {
    content: '';
    width: 40px;
    display: inline-block;
}
ol ol > li {
    display: block;
}

ol ol > li:before {
    content: counters(item, '.') '. ';
    margin-left: -30px;
}
