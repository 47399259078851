@media (max-width: 600px) {
    .img-height {
        max-height: 250px;
    }
}

@media (min-width: 601px) {
    .img-height {
        max-height: 450px !important;
    }
}

@media (min-width: 800px) {
    .img-height {
        max-height: 525px !important;
    }
}

@media (min-width: 1024px) {
    .img-height {
        max-height: 600px !important;
    }
}

