.event-schedule-date {
    font-weight: 700 !important;
    color: white !important;
    opacity: 1 !important;
}

@media only screen and (max-width: 1169px) {
    .event-schedule-date {
        font-weight: 700 !important;
        color: #002e3c !important;
        opacity: 1 !important;
    }
}

.vertical-timeline::before {
    content: '';
    position: absolute;
    top: 0;
    left: 18px;
    height: 100%;
    width: 2px;
    background: var(--line-color);
}
