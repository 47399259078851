.loading-screen {
  position: fixed; 
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #002E3C; 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; 
}

.loading-screen img {
  width: 100%;
  height: 100%;
  object-fit: cover; 
}
