@media only screen and (width >= 1120px) {
    .flip-card {
        width: 33% !important;
    }
    .basic-card {
        width: 33% !important;
    }
}
@media only screen and (1120px > width >= 640px) {
    .flip-card {
        width: 50% !important;
    }
    .basic-card {
        width: 50% !important;
    }
}
@media only screen and (640px > width) {
    .flip-card {
        width: 100% !important;
    }
    .basic-card {
        width: 100% !important;
    }
}

.rule-card:hover {
    backface-visibility: hidden !important;
}

.flip-card {
    position: relative !important;
    height: 25rem !important;
    margin-bottom: 2em !important;
    perspective: 150em !important;
}

.flip-card:hover .rule-card {
    cursor: pointer !important;
}

.rule-card {
    transition: all 0.8s ease !important;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    overflow: hidden !important;
    backface-visibility: hidden !important;
    -webkit-backface-visibility: hidden !important;
    height: 25rem !important;
    width: 100% !important;
}

.front-card:hover {
    transform: translateY(-1em) !important;
}

.front-card {
    transform: rotateY(0deg) !important;
}

.front-card > div {
    background: #002e3c !important;
}

.front-card h4 {
    color: #f1efed !important;
    font-weight: 700 !important;
}

.clicked .front-card {
    transform: rotateY(-180deg) !important;
}
.back-card {
    transform: rotateY(180deg) !important;
}

.back-card > div {
    background: #ffd700 !important;
}

.clicked .back-card {
    transform: rotateY(0) !important;
}
.lsgs-b1d71--cards-custom-item__large .lsgs-b1d71--cards-custom-item-content {
    margin: 24px !important;
    margin-top: 96px !important;
}
