.bannerOverlay {
    position: absolute !important;
    left: 0px !important;
    right: 0px !important;
    bottom: 0px !important;
    display: flex;
    justify-content: center !important;
}

.banner {
    display: none;
}

@media screen and (max-height: 700px) {
    .bannerOverlay {
        display: none;
    }

    .banner {
        display: flex !important;
        justify-content: center;
        background: #002e3c
    }
}

