.accordion h3 {
        font-weight: bold;
        color:#002e3c;
}

.accordionGroup h3:hover {
        font-weight: bold;
        color:#002e3c;
}

.accordionGroup > div:has(.accordion) >div {
        border: 3px solid #FFD700;
        border-radius: 20px;
        padding: 1em 0;
        margin: .5em 0;
}
