.carousel .control-dots .dot {
    background-color: #3A7E8A !important;
}

.carousel .control-dots .dot.selected {
    width: 14px;
    height: 14px;
    background-color: #E6007E !important;
}

.carousel .control-dots {
    position: absolute;
    bottom: 0;
    margin: -4px 0;
    padding: 0;
    text-align: center;
    width: 100%;
    z-index: 1;
}

.carousel .control-dots .dot {
    transition: opacity 0.25s ease-in;
    opacity: 1;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0);
    background: #fff;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    cursor: pointer;
    display: inline-block;
    margin: 0 8px;
}
