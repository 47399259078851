/* .lsgs-b1d71--header-container-segment-label {
    font-weight: 900 !important;
    font-size: 32px !important;
    letter-spacing: 0.2rem !important;

    background: linear-gradient(
        90deg,
        rgba(255, 215, 0, 1) 0%,
        rgba(213, 219, 182, 1) 80%
    );
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;

    text-shadow: 4px 4px rgba(213, 219, 182, 0.25) !important;
} */

.lsgs-b1d71--icon-link-label {
    font-size: 16px !important;
}
