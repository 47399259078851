:root {
    --websiteHeight: 40px;
    --websiteMargin: 15%;
    --black: #000000;
    --yellow: #ffd700;
    --petrol: #002e3c;
    --grey: #f1efed;
    --h2: 50px;
    --h1: 32px;
    /* font-family: var(--lsg-font-family-400, 'Gotham', Verdana, Arial, sans-serif); */
    /* font-family: var(--lsg-font-family-500, 'Gotham', Verdana, Arial, sans-serif); */
    /* font-family: var(--lsg-font-family-700, 'Gotham', Verdana, Arial, sans-serif); */
    --lsg-font-family-400: -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    --lsg-font-family-500: -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    --lsg-font-family-700: -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;

    color: var(var(--petrol));
}

@media screen and (max-width: 1400px) {
    :root {
        --websiteHeight: 40px;
        --websiteMargin: 10%;
    }
}

@media screen and (max-width: 1200px) {
    :root {
        --websiteHeight: 35px;
        --websiteMargin: 8%;
    }
}

@media screen and (max-width: 900px) {
    :root {
        --websiteHeight: 30px;
        --websiteMargin: 6%;
    }
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.footer h3 {
    color: var(--petrol);
}

.underline_link{
    text-decoration: underline;
}
