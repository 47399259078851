.lsgs-b1d71--accordion-header-text {
    font-size: 18px !important;
    color: black !important;
}

.lsgs-b1d71--accordion {
    font-weight: 700 !important;
}

.lsgs-b1d71--copy-text {
    max-width: 90% !important;
    hyphens: none;
}

.lsgs-b1d71--h4 {
    max-width: 90% !important;
    hyphens: none;
}

.accordion h3 {
    font-weight: bold;
    color: #002e3c;
}

.accordionGroup h3:hover {
    font-weight: bold;
    color: #002e3c;
}
