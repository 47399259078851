.lsgs-b1d71--brandbar-claim {
    font-size: 18px !important;
    font-weight: 700 !important;
}

@media screen and (min-width: 1680px) {
    .lsgs-b1d71--footer-inner,
    .lsgs-b1d71--footer-contact-area {
        max-width: 1680px !important;
    }
}

@media screen and (min-width: 1680px) {
    .lsgs-b1d71--footer-inner,
    .lsgs-b1d71--footer-contact-area {
        width: 100% !important;
    }
}

.lsgs-b1d71--navigation-link-inner {
    font-size: 14px !important;
}

.links-intro {
    color: #dbe2e5;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.5px;
}
